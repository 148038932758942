.MuiTableRow-root > * {
  padding: 10px;
  background-color: #563e8d;
  color: white;

}

.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
  color: white;
}

.Table td,
th {
  border: none !important;
  color: white;
}

.Table:first-child {
  border-radius: 15px !important;
  color: white;
}
.status {
  padding: 8px;
  border-radius: 9px;
}

.Details {
  color: #00b5ff !important;
}
.Details:hover{
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .Table {
    width: 170%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}


@media screen and (max-width: 768px) {
  .Table{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23rem;
  }
}